import React from "react";

interface FooterProps {
  logo?:  any
}

const Footer: React.FC<FooterProps> = (props) => (
  <footer className="footer has-background-black is-padding-bottom-3">
    <div className="content has-text-centered">
      {props.logo &&
        props.logo
      }
      <p className="is-size-7 mt-0 has-text-grey-light">主催：学生団体MARKS / 株式会社エイジ・エンタテインメント<br />
        企画・制作：MISCOLLE</p>
      <p className="is-size-7 mt-5">Copyright © 2022 学生団体MARKS / 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
    </div>
    <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope"/>
          </span>
      <span>お問い合わせ</span>
    </a>
  </footer>
);

export default Footer;
