import React, { useEffect } from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import { Link } from 'react-navi';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import Footer from "@2023-mom-web/components/src/footer";

export default compose(
    mount({
        '/': route({
            getView: ({ context }) => (
                <>
                    <Helmet>
                        <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
                        <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
                    </Helmet>
                    <App />
                </>
            ),
            title: process.env.REACT_APP_WEBSITE_NAME,
        }),
    }),
);

const App = () => {
    return (
        <div id="thanks">
            <section className="hero is-black is-fullheight">
                <div className="hero-body">
                    <div className="container is-slim">
                        <p className="title is-size-5 has-text-centered">フォームのご提出を完了致しました </p>
                        <div className="notification has-text-dark is-size-7 is-padding-right-1 is-padding-left-1">
                            この度は「{process.env.REACT_APP_WEBSITE_NAME}」にエントリー頂きありがとうございました。
                        </div>
                        <Link className="button is-medium has-crown is-fullwidth" href="/">
                            トップに戻る
                        </Link>
                    </div>
                </div>
                <div className="hero-foot">
                    <Footer logo={<Logo width={150} />} />
                </div>
            </section>
        </div>
    );
};
