import React from 'react';

export default function ShowroomButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
        <a {...props} className="button is-black">
            <span className="icon is-small">
                <img src="/image/swr.png" />
            </span>
        </a>
    );
}
