import React from 'react';
import { Link } from 'react-navi';

interface NavigationProps {
    additional: any;
    isFinal?: boolean;
}

const Navigation: React.FC<NavigationProps> = props => (
    <div className="tabs as-navigation is-centered is-family-futura mb-0 has-background-dark">
        <ul>
            <li>
                <Link href="/entries">{props.isFinal ? "FINALIST": "ENTRIES"}</Link>
            </li>

            {/*
            <li>
                <Link href="/#event">EVENT</Link>
            </li>
            */}
            <li className="is-hidden-mobile">
                <Link href="/#history">HISTORY</Link>
            </li>
            {props.isFinal && <li>
                <Link href="/entries/all">ENTRIES</Link>
            </li>}
            {props.additional}
        </ul>
    </div>
);

export default Navigation;
