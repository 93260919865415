import React from 'react';
import { mount, compose } from 'navi';
import top from "./top";
import entries from "./entries";
import profiles from "./profiles";
const fromPairs = require('lodash.frompairs');
import entry from "./entry";
import thanks from "./thanks";

// NOTE:- Do Not Use until entry released
// import news from "./news";

const routes = compose(
    mount({
        '/': top,
        '/entries': entries,
        '/profiles':mount({
            ...fromPairs(profiles.map(profile => [`/${profile.id}`, profile.getPage])),
        }),
        '/entry': entry,
        '/thanks': thanks,
        // '/news': news,
    }),
);

export default routes;